import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  Button,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

const DemoSignupModal = ({ isOpen, onClose }) => {
  const { colorMode } = useColorMode();
  const router = useRouter();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    userType: "candidate",
  });
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserTypeChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      userType: value,
    }));
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validation
    if (!formData.name || !formData.email || !formData.phone || !formData.userType) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      sessionStorage.setItem("formData", JSON.stringify(formData));
      router.push("/demoPage");
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent bg={colorMode === "dark" ? "gray.800" : "white"}>
        <ModalHeader textAlign="center">Request a Demo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Enter your company name"
              />
            </FormControl>

            <FormControl as="fieldset" isRequired>
              <FormLabel as="legend">I am a</FormLabel>
              <RadioGroup
                value={formData.userType}
                onChange={handleUserTypeChange}
              >
                <VStack align="start">
                  <Radio value="recruiter">Recruiter</Radio>
                  <Radio value="interviewer">Interviewer</Radio>
                  <Radio value="trainer">Trainer</Radio>
                  <Radio value="candidate">Candidate</Radio>
                </VStack>
              </RadioGroup>
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              width="full"
              // onClick={handleSubmit}
            >
              Submit
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DemoSignupModal;
